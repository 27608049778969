/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import styled from "styled-components"
import { primaryColor } from "../../colors"

import Header from "../organisms/header"
import Footer from "../organisms/footer"
import Jumbotron from "react-bootstrap/Jumbotron"

import MainContainer from "../atoms/mainContainer"

const Hero = styled(Jumbotron)`
  background-color: ${primaryColor};
  margin-bottom: 0px;
  border-radius: 0px;

  color: #ffffff !important;
  
  h2 {
    color: #ffffff !important;
  }
`

const Layout = ({ children, fluid, title, lang, location }) => (
  <StaticQuery query={graphql`
      query {
        site {
          siteMetadata {
            title,
            company
          }
        }
      }
    `} render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} lang={lang} location={location} />
        <Hero>
          <MainContainer>
            <h2 className="mt-5 mb-0 px-3 animated fadeInUp" style={{animationDelay: "100ms"}}>
              {title}
            </h2>
          </MainContainer>
        </Hero>
        {
          fluid ?
          <div>{children}</div> :
          <MainContainer className="p-3 animated fadeIn" style={{animationDelay: "150ms"}}>
            {children}
          </MainContainer>
        }
        <Footer company={data.site.siteMetadata.company} lang={lang} />
      </>
    )} />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
}

export default Layout
